var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"create-activate-container pl-md-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:{
          'text-h6 primary--text': true,
          'text-center': _vm.$mobileBreakpoint,
        }},[_vm._v(" Create / Activate Account ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('v-stepper',{attrs:{"flat":"","vertical":""},model:{value:(_setup.currentStep),callback:function ($$v) {_setup.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-step',{class:{
            '': true,
            'text-body-1': !_vm.$mobileBreakpoint,
            'stepper-step-mobile text-subtitle-2': _vm.$mobileBreakpoint,
          },attrs:{"step":"1"}},[_c('span',{class:{ 'black--text': !_vm.$mobileBreakpoint }},[_vm._v(" Enter ID/Business Number ")])]),_c('v-stepper-content',{class:{ 'stepper-content-mobile': _vm.$mobileBreakpoint },attrs:{"step":"1"}},[_c(_setup.EnterIdNumber,{attrs:{"account-details-models":_setup.accountDetailsModels,"check-account-request-status":_setup.checkAccountRequestStatus},on:{"check-account":_setup.onCheckAccount}})],1),_c('v-stepper-step',{class:{
            'text-body-1': !_vm.$mobileBreakpoint,
            'stepper-step-mobile text-subtitle-2': _vm.$mobileBreakpoint,
          },attrs:{"step":"2"}},[_c('span',{class:{ 'black--text': !_vm.$mobileBreakpoint }},[_vm._v(" Verify Identity ")])]),_c('v-stepper-content',{class:{ 'stepper-content-mobile': _vm.$mobileBreakpoint },attrs:{"step":"2"}},[_c(_setup.VerifyIdentity,{attrs:{"account-details-for-verification-stage":_setup.accountDetailsForVerificationStage,"account-details-models":_setup.accountDetailsModels,"non-activated-account-exists":_setup.nonActivatedAccountExists,"id-or-business-text":_setup.idOrBusinessText,"user-redirected-from-login":_setup.userRedirectedFromLogin},on:{"previous-clicked":_setup.goToEnterIdNumberStep,"next-clicked":_setup.goToAccountDetailsStep}})],1),(_setup.showAccountDetailsStep)?[_c('v-stepper-step',{class:{
              'text-body-1': !_vm.$mobileBreakpoint,
              'stepper-step-mobile text-subtitle-2': _vm.$mobileBreakpoint,
            },attrs:{"step":"3"}},[_c('span',{class:{ 'black--text': !_vm.$mobileBreakpoint }},[_vm._v(" "+_vm._s(_setup.accountDetailsStepLabel)+" ")])]),_c('v-stepper-content',{class:{ 'stepper-content-mobile': _vm.$mobileBreakpoint },attrs:{"step":"3"}},[_c(_setup.EnterAccountDetails,{attrs:{"account-details-models":_setup.accountDetailsModels,"is-individual":_setup.isIndividual,"should-create-account":_setup.shouldCreateAccount,"should-activate-account":_setup.shouldActivateAccount,"is-disabled":_setup.isDisabled,"should-show-date-of-registration":_setup.shouldShowDateOfRegistration,"validation-error-items":_setup.errorItems,"validating-account-data":_setup.accountDataValidationMutation.isLoading.value},on:{"previous-clicked":_setup.goToVerifyIdentityStep,"next-clicked":_setup.validateAccountData}})],1),_c('v-stepper-step',{class:{
              'text-body-1': !_vm.$mobileBreakpoint,
              'stepper-step-mobile text-subtitle-2': _vm.$mobileBreakpoint,
            },attrs:{"step":"4"}},[_c('span',{class:{ 'black--text': !_vm.$mobileBreakpoint }},[_vm._v(" Enter Verification Code ")])]),_c('v-stepper-content',{class:{ 'stepper-content-mobile': _vm.$mobileBreakpoint },attrs:{"step":"4"}},[(_setup.currentStep === _setup.steps.VERIFY_OTP)?_c(_setup.Otp,{attrs:{"phoneNumber":_setup.accountDetailsModels.phoneNumber1,"countryCode":_setup.accountDetailsModels.phoneNumber1CountryCode,"emailAddress":_setup.accountDetailsModels.emailAddress,"otpInvalid":_setup.otpInvalid,"verifying":_setup.creatingOrActivatingAccount,"resetOtpStatusToInitialFn":_setup.resetOtpStatusToInitial,"sendOtpImmediately":_setup.countyIsKirinyaga},on:{"verifyOtpClicked":_setup.createOrActivateAccount},scopedSlots:_vm._u([(!_setup.hasEnteredEmailAddress)?{key:"additionalResendOtp",fn:function({ resetOtpToSendStage }){return [_vm._v(" Or "),_c('a',{on:{"click":function($event){return _setup.goBackToAccountDetailsToAddEmailAddress(
                      resetOtpToSendStage
                    )}}},[_vm._v("click here")]),_vm._v(" to go back and add an email address and receive your verification code via your email address ")]}}:null],null,true)}):_vm._e(),_c(_setup.PreviousButton,{staticClass:"mt-4 mt-md-10",attrs:{"block":false},on:{"click":_setup.goToAccountDetailsStep}})],1),_c('v-stepper-step',{class:{
              'text-body-1': !_vm.$mobileBreakpoint,
              'stepper-step-mobile text-subtitle-2': _vm.$mobileBreakpoint,
            },attrs:{"step":"5"}},[_c('span',{class:{ 'black--text': !_vm.$mobileBreakpoint }},[_vm._v(" Proceed to login ")])]),_c('v-stepper-content',{class:{ 'stepper-content-mobile': _vm.$mobileBreakpoint },attrs:{"step":"5"}},[_c(_setup.ProceedToLogin,{attrs:{"should-create-account":_setup.shouldCreateAccount}})],1)]:_vm._e()],2)],1)],1),_c('v-row',{staticClass:"mt-8",attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',[_c('span',{staticClass:"text-body-2"},[_vm._v("Already have an account?")])])]),_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.ClickHereToLoginBtn)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }