<script setup>
import { computed, ref, watchEffect } from "vue";
import { arrayNotEmpty } from "@/utils/functions";

const props = defineProps({
  userAgrees: {
    type: Boolean,
    default: false,
  },
  errorMessages: {
    type: Array,
    default: [],
  },
});

const emit = defineEmits(["update:userAgrees"]);

const innerUserAgrees = computed({
  get() {
    return props.userAgrees;
  },
  set(newVal) {
    if (newVal === true) {
      dialogOpen.value = false;
    }
    emit("update:userAgrees", newVal);
  },
});

const dialogOpen = ref(false);

function showDisclaimerDialog() {
  dialogOpen.value = true;
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="auto" class="px-0">
        <v-checkbox
          dense
          hide-details="auto"
          :input-value="innerUserAgrees"
          readonly
          :error-messages="errorMessages"
          @click="showDisclaimerDialog"
        >
          <template #label>
            <span
              :class="{
                'text-body-2': true,
                'black--text': !$mobileBreakpoint,
              }"
              >I have read and understood the terms and conditions</span
            >
          </template>
        </v-checkbox>
        <!-- </v-btn> -->
      </v-col>
    </v-row>

    <v-dialog v-model="dialogOpen" max-width="800">
      <v-card class="pb-2">
        <v-card-title>
          <span :class="{ 'text-body-2 font-weight-bold': $mobileBreakpoint }"
            >Terms and Conditions</span
          >
          <v-spacer />
          <v-btn icon @click="dialogOpen = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          We encourage users to enter accurate and up-to-date data when using
          our website. The accuracy and completeness of the data you provide are
          essential to ensure the proper functioning of our services. We shall
          not be held liable for any inaccuracies or errors resulting from the
          use of incomplete or inaccurate data provided by users.
          <br />
          By using this website, you acknowledge that any information you
          provide is accurate to the best of your knowledge and that you will
          take responsibility for the consequences of providing false or
          misleading information. We reserve the right to suspend or terminate
          your access to our services if we determine that the data provided is
          false, misleading, or violates any applicable laws or regulations.
        </v-card-text>

        <v-card-actions>
          <v-checkbox dense hide-details="auto" v-model="innerUserAgrees">
            <template #label>
              <span class="text-body-2"
                >I have read and understood the above terms and conditions</span
              >
            </template>
          </v-checkbox>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
