<script setup>
import Container from "./Container.vue";
import PreviousButton from "./PreviousButton.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import PhoneNumberInput from "@/components/inputs/PhoneNumberInput.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import store from "@/store/index";
import {
  fieldRequired,
  validateIfNotEmpty,
  emailValid,
  passwordConfirmation,
  passwordRules,
  minLength,
} from "@/utils/input_validation.js";
import {
  getJsDateAsYMD,
  convertYearsToMs,
  addPostalCodeToPostalTown,
} from "@/utils/functions";
import {
  examplePhoneNumber,
  passwordSpecialCharacters,
} from "@/utils/constants";
import { countyIsKirinyaga, scrollToError } from "@/utils/vue_helpers";
import { ref, computed, watchEffect, watch } from "vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import TermsAndConditions from "./TermsAndConditions.vue";
import PersonWithDisability from "@/components/utils/PersonWithDisability.vue";
import NextButton from "./NextButton.vue";

const props = defineProps({
  accountDetailsModels: {
    type: Object,
    default: null,
  },
  isIndividual: {
    type: Boolean,
    default: false,
  },
  shouldCreateAccount: {
    type: Boolean,
    default: false,
  },
  shouldActivateAccount: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  shouldShowDateOfRegistration: {
    type: Boolean,
    default: false,
  },
  validationErrorItems: {
    type: Object,
    default: false,
  },
  validatingAccountData: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["next-clicked"]);

const supportingFormData = computed(() => {
  return store.getters.getSupportingFormData;
});

const idOrBusinessText = computed(() => {
  if (props.isIndividual === true) {
    return "ID";
  }
  return "Business";
});

const primaryPhoneNumberInput = ref(null);

const secondaryPhoneNumberInput = ref(null);

const form = ref(null);

const userAgreesWithDisclaimer = ref(false);

const websiteDisclaimerErrorMessages = ref([]);

function validateDisclaimer() {
  if (!userAgreesWithDisclaimer.value) {
    websiteDisclaimerErrorMessages.value = [
      "Please read and agree with the terms and conditions",
    ];
  } else {
    websiteDisclaimerErrorMessages.value = [];
  }
}

watch(userAgreesWithDisclaimer, () => {
  validateDisclaimer();
});

function validateFieldsAndMoveToNextStep() {
  const formValid = form.value.validate();
  const primaryPhoneNumberIsValid = primaryPhoneNumberInput.value.validate();
  const secondaryPhoneNumberIsValid =
    secondaryPhoneNumberInput.value.validate();
  validateDisclaimer();

  if (
    formValid &&
    primaryPhoneNumberIsValid &&
    secondaryPhoneNumberIsValid &&
    userAgreesWithDisclaimer.value
  ) {
    emit("next-clicked");
  } else {
    scrollToError({
      containerElement: form.value.$el,
      couldBePhoneNumberError: true,
    });
  }
}

const passwordConfirmationRule = passwordConfirmation({
  passwordGetter: () => props.accountDetailsModels.password,
});

const eighteenYearsAgo = getJsDateAsYMD(
  new Date(Date.now() - convertYearsToMs(18))
);

const today = getJsDateAsYMD(new Date());

const maxDate = ref(eighteenYearsAgo);

const dateFieldRules = ref([fieldRequired]);

watchEffect(() => {
  if (props.isIndividual) {
    maxDate.value = eighteenYearsAgo;
    props.accountDetailsModels.dateOfBirth = eighteenYearsAgo;
  } else {
    maxDate.value = today;
    props.accountDetailsModels.dateOfBirth = today;
  }
});

const emailRules = computed(() => {
  const fieldIsRequired = !countyIsKirinyaga.value;
  return [
    fieldIsRequired ? fieldRequired : () => true,
    validateIfNotEmpty([emailValid]),
  ];
});

const firstNameLabel = computed(() => {
  return countyIsKirinyaga ? "Surname" : "First Name";
});

const lastNameLabel = computed(() => {
  return countyIsKirinyaga ? "First Name" : "Last Name";
});

const dateOfBirthLabel = computed(() => {
  if (props.isIndividual) {
    return "Date of Birth";
  }
  return "Date of Registration";
});
</script>

<template>
  <Container>
    <template #content>
      <v-form @keyup.native.enter="validateFieldsAndMoveToNextStep" ref="form">
        <v-container fluid class="px-0">
          <v-row>
            <!-- ID number -->
            <v-col cols="12" :md="shouldCreateAccount ? '5' : '12'">
              <LabelledField :label="`${idOrBusinessText} Number`" required>
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  :readonly="shouldActivateAccount"
                  disabled
                  v-model="accountDetailsModels.identificationNumber"
                  hide-details="auto"
                />
              </LabelledField>
            </v-col>

            <!-- Customer type -->
            <v-col v-if="shouldCreateAccount" cols="12" md="7">
              <LabelledField label="Customer Type" required>
                <v-select
                  :items="supportingFormData.customerTypes"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  class="text-field-background"
                  required
                  :readonly="shouldActivateAccount"
                  :disabled="shouldActivateAccount"
                  v-model="accountDetailsModels.customerTypeId"
                  hide-details="auto"
                ></v-select>
              </LabelledField>
            </v-col>
          </v-row>

          <!-- Names -->
          <v-row v-if="isIndividual && shouldCreateAccount">
            <!-- First name, surname in kiri -->
            <v-col cols="12" md="4">
              <LabelledField
                required
                :hint="$mobileBreakpoint ? 'This is your family name' : null"
              >
                <template #label>
                  <v-tooltip left nudge-left="0">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <span>{{ firstNameLabel }}</span>
                        <v-icon v-if="!$mobileBreakpoint" small class="mx-1"
                          >mdi-information-outline</v-icon
                        >
                        <span class="error--text">{{ ` *` }}</span>
                      </div>
                    </template>
                    <span>This is your family name</span>
                  </v-tooltip>
                </template>

                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  v-model="accountDetailsModels.firstName"
                  :rules="[fieldRequired]"
                  hide-details="auto"
                >
                </v-text-field>
              </LabelledField>
            </v-col>

            <!-- Last name, firstname in kiri -->
            <v-col cols="12" md="4">
              <LabelledField :label="lastNameLabel" required>
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  v-model="accountDetailsModels.lastName"
                  :rules="[fieldRequired]"
                  hide-details="auto"
                ></v-text-field>
              </LabelledField>
            </v-col>

            <!-- Other name -->
            <v-col cols="12" md="4">
              <LabelledField label="Other Names">
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  v-model="accountDetailsModels.otherName"
                  hide-details="auto"
                ></v-text-field>
              </LabelledField>
            </v-col>
          </v-row>

          <!-- Organisation name -->
          <v-row v-else-if="shouldCreateAccount">
            <v-col cols="12">
              <LabelledField label="Organisation Name" required>
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  v-model="accountDetailsModels.organisationName"
                  :rules="[fieldRequired]"
                  hide-details="auto"
                ></v-text-field>
              </LabelledField>
            </v-col>
          </v-row>

          <!-- Disabled customer name for when activating account -->
          <v-row v-else-if="shouldActivateAccount">
            <v-col cols="12">
              <LabelledField label="Name">
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  v-model="accountDetailsModels.customerName"
                  disabled
                  hide-details="auto"
                ></v-text-field>
              </LabelledField>
            </v-col>
          </v-row>

          <v-row>
            <!-- Phone number 1 -->
            <v-col cols="12" md="6" class="">
              <LabelledField
                :label="`Phone Number 1 (e.g. ${examplePhoneNumber})`"
                required
                hint="Ensure this Safaricom number can receive <br/> promotional SMS by dialing (*456*9*5#)"
              >
                <PhoneNumberInput
                  ref="primaryPhoneNumberInput"
                  v-model="accountDetailsModels.phoneNumber1"
                  :countryCode.sync="
                    accountDetailsModels.phoneNumber1CountryCode
                  "
                  :rules="[fieldRequired, minLength(9)]"
                  :error-messages="
                    validationErrorItems.phone_number1.errorValue ?? []
                  "
                />
              </LabelledField>
            </v-col>

            <!-- Phone number 2 -->
            <v-col cols="12" md="6">
              <LabelledField
                :label="`Phone Number 2 (e.g. ${examplePhoneNumber})`"
              >
                <PhoneNumberInput
                  ref="secondaryPhoneNumberInput"
                  v-model="accountDetailsModels.phoneNumber2"
                  :countryCode.sync="
                    accountDetailsModels.phoneNumber2CountryCode
                  "
                  :rules="[validateIfNotEmpty([minLength(9)])]"
                  :error-messages="
                    validationErrorItems.phone_number2.errorValue ?? []
                  "
                />
              </LabelledField>
            </v-col>
          </v-row>

          <v-row>
            <!-- Email Address -->
            <v-col cols="12">
              <LabelledField
                label="Email Address"
                :required="!countyIsKirinyaga"
                hint="We will communicate with you via this email"
              >
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  v-model="accountDetailsModels.emailAddress"
                  :rules="emailRules"
                  hide-details="auto"
                  :error-messages="
                    validationErrorItems.email_address.errorValue ?? []
                  "
                ></v-text-field>
              </LabelledField>
            </v-col>
          </v-row>

          <!-- Password and confirmation -->
          <v-row v-if="!countyIsKirinyaga">
            <v-col cols="12" md="6">
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <PasswordInput
                      label="Password"
                      v-model="accountDetailsModels.password"
                      :rules="[fieldRequired, ...passwordRules]"
                    />
                  </div>
                </template>

                <span class="text-caption">
                  Password must:
                  <ul>
                    <li>Be least 8 characters long</li>
                    <li>Have at least one uppercase character</li>
                    <li>Have at least one number</li>
                    <li>
                      Have at least one special character ({{
                        passwordSpecialCharacters
                      }})
                    </li>
                  </ul>
                </span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" md="6">
              <PasswordInput
                label="Password Confirmation"
                showPasswordLabel="Show Password Confirmation"
                v-model="accountDetailsModels.passwordConfirmation"
                :rules="[fieldRequired, passwordConfirmationRule]"
              />
            </v-col>
          </v-row>

          <v-row>
            <!-- Gender -->
            <v-col v-if="shouldCreateAccount && isIndividual" cols="12" md="6">
              <LabelledField label="Gender" required>
                <v-select
                  :items="supportingFormData.genders"
                  :rules="[fieldRequired]"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  v-model="accountDetailsModels.genderId"
                  class="text-field-background"
                  hide-details="auto"
                />
              </LabelledField>
            </v-col>

            <!-- Date of birth or date of registration -->
            <v-col cols="12" md="6">
              <LabelledField :label="dateOfBirthLabel" required>
                <DatePicker
                  :rules="dateFieldRules"
                  :max="maxDate"
                  :textFieldProps="{
                    outlined: true,
                    dense: true,
                    class: 'text-field-background',
                    'hide-details': 'auto',
                  }"
                  only-show-value-on-change
                  v-model="accountDetailsModels.dateOfBirth"
                />
              </LabelledField>
            </v-col>

            <!-- Postal address -->
            <v-col v-if="shouldCreateAccount" cols="12" md="6">
              <LabelledField label="Postal Address">
                <v-text-field
                  class="text-field-background"
                  outlined
                  dense
                  required
                  v-model="accountDetailsModels.postalAddress"
                  hide-details="auto"
                ></v-text-field>
              </LabelledField>
            </v-col>

            <!-- Postal town -->
            <v-col v-if="shouldCreateAccount" cols="12" md="6">
              <LabelledField label="Postal Town">
                <v-autocomplete
                  :items="supportingFormData.postalTowns"
                  item-value="postal_town"
                  :item-text="addPostalCodeToPostalTown"
                  cache-items
                  outlined
                  dense
                  hide-no-data
                  hide-details="auto"
                  class="text-field-background"
                  v-model="accountDetailsModels.postalTown"
                ></v-autocomplete>
              </LabelledField>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <TermsAndConditions
                :userAgrees.sync="userAgreesWithDisclaimer"
                :errorMessages="websiteDisclaimerErrorMessages"
              />
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="auto">
              <PersonWithDisability />
            </v-col>
          </v-row>

          <template v-if="false">
            <!-- Client shouldn't be able to indicate disability status -->
            <v-row align="center" v-if="shouldCreateAccount">
              <v-col cols="auto">
                <v-radio-group
                  dense
                  v-model="accountDetailsModels.disabled"
                  row
                  hide-details="auto"
                  class="mt-0"
                  disabled
                >
                  <template #label
                    ><span class="text-body-1"
                      >Do you have a disability?</span
                    ></template
                  >
                  <v-radio label="Yes" :value="true" color="primary" />
                  <v-radio label="No" :value="false" color="primary" />
                </v-radio-group>
              </v-col>

              <v-col cols="auto">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon color="secondary" v-on="on" v-bind="attrs"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span>
                    Please visit a county office to change your disability
                    status
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row v-if="isDisabled">
              <template>
                <v-col cols="6">
                  <LabelledField label="NCPLWD Number" required>
                    <v-text-field
                      class="text-field-background"
                      outlined
                      dense
                      :rules="[fieldRequired]"
                      v-model="accountDetailsModels.ncplwdNumber"
                      hide-details="auto"
                    />
                  </LabelledField>
                </v-col>

                <v-col cols="6">
                  <LabelledField label="Date of Issuance" required>
                    <DatePicker
                      :rules="[fieldRequired]"
                      :textFieldProps="{
                        outlined: true,
                        dense: true,
                        class: 'text-field-background',
                      }"
                      v-model="accountDetailsModels.ncplwdNumberIssuanceDate"
                      hide-details="auto"
                    />
                  </LabelledField>
                </v-col>
              </template>

              <v-col cols="12">
                <v-divider />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-form>
    </template>

    <template #nextButton>
      <!-- <v-btn depressed rounded color="secondary" :block="true" class="text-none" @click="validateFieldsAndMoveToNextStep"
        :loading="validatingAccountData" v-bind="$attrs">Next</v-btn> -->
      <NextButton
        :loading="validatingAccountData"
        @click="validateFieldsAndMoveToNextStep"
      >
        <template #loadingText> Please Wait </template>
      </NextButton>
    </template>

    <template #previousButton>
      <PreviousButton @click="$emit('previous-clicked')" />
    </template>
  </Container>
</template>

<style lang="scss" scoped>
:deep(.password-container) {
  & input,
  & .v-input__slot {
    cursor: help;
  }
}
</style>
