<script setup>
import Container from "./Container.vue";
import NextButton from "./NextButton.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import ClickHereToLoginBtn from "@/components/utils/ClickHereToLoginBtn.vue";
import { fieldRequired } from "@/utils/input_validation.js";
import { checkAccountStatus, requestStatus } from "@/utils/constants";
import { computed, ref } from "vue";

const props = defineProps({
  accountDetailsModels: {
    type: Object,
    default: null,
  },
  checkAccountRequestStatus: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["check-account"]);

const checkingAccount = computed(() => {
  if (props.checkAccountRequestStatus === requestStatus.SENDING) {
    return true;
  }
  return false;
});

const errorWhileCheckingAccount = computed(() => {
  if (props.checkAccountRequestStatus === requestStatus.ERROR) {
    return true;
  }
  return false;
});

const accountAlreadyActivated = computed(() => {
  return (
    props.checkAccountRequestStatus === checkAccountStatus.accountActivated
  );
});

const form = ref(null);

async function validateFormAndCheckAccount() {
  const formValid = form.value.validate();
  if (formValid) {
    try {
      emit("check-account");
    } catch (e) {}
  }
}

const idNumberFieldRules = [fieldRequired];
</script>

<template>
  <Container>
    <template #content>
      <v-container fluid>
        <v-row justify="start">
          <v-col cols="12">
            <v-form @submit.prevent="validateFormAndCheckAccount" ref="form">
              <LabelledField label="ID / Business Number" required>
                <v-text-field
                  class="mt-2 text-field-background"
                  outlined
                  dense
                  v-model="accountDetailsModels.identificationNumber"
                  prepend-inner-icon="mdi-account"
                  :rules="idNumberFieldRules"
                  hide-details="auto"
                ></v-text-field>
              </LabelledField>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="errorWhileCheckingAccount" no-gutters justify="start">
          <v-col cols="12">
            <div class="error--text subtitle-2">
              An error occurred, please try again
            </div>
          </v-col>
        </v-row>

        <template v-if="accountAlreadyActivated">
          <v-row dense>
            <v-col cols="12">
              <div class="text-body-2">
                You already have an existing account
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="auto">
              <ClickHereToLoginBtn />
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col
            :cols="$mobileBreakpoint ? 'auto' : checkingAccount ? '7' : '4'"
          >
            <NextButton
              :loading="checkingAccount"
              @click="validateFormAndCheckAccount"
            >
              <template #loadingText> Checking ID / Business Number </template>
            </NextButton>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </Container>
</template>
