<script setup>
import LoadingButton from "@/components/utils/LoadingButton.vue";
import { routes } from "@/router/routes";
import { computed } from "vue";

const props = defineProps({
  shouldCreateAccount: {
    type: Boolean,
    default: false
  }
})

const createdOrActivatedText = computed(() => {
  if (props.shouldCreateAccount) {
    return "created";
  }
  return "activated";
});
</script>

<template>
  <v-container fluid :class="{ 'text-body-2': $mobileBreakpoint }">
    <v-row>
      <v-col cols="12" class="d-flex align-start align-md-center">
        <span class="success--text">
          Your account has been {{ createdOrActivatedText }}, you may login
        </span>
        <v-icon :size="$mobileBreakpoint ? 24 : 32" class="success--text ml-2">mdi-check-circle</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <router-link :to="routes.login" :style="{
          textDecoration: 'none',
        }">
          <!-- <v-btn depressed rounded block :class="['text-none  white--text', 'button-background-secondary-1']">
            <span>Proceed to login</span>
            <v-icon class="white--text" right>
              <slot>mdi-login</slot>
            </v-icon>
          </v-btn> -->
          <LoadingButton rounded :class="['text-none  white--text', 'button-background-secondary-1']">
            <template #default>
              <span>Proceed to login</span>
              <v-icon class="white--text" right>
                <slot>mdi-login</slot>
              </v-icon>
            </template>
          </LoadingButton>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>